<script setup lang="ts">
import type { SocialEntity, User } from '@prisma/client';
import type { BasicUser, UserData } from '~/types/users';
import type { BasicEntity, BasicEntityExt } from '~/types/socialEntities';

const props = withDefaults(
  defineProps<{
    url?: string;
    initial?: string;
    user?: User | BasicUser | UserData;
    socialEntity?: SocialEntity | BasicEntity | BasicEntityExt;
    isLoading?: boolean;
    size?: number;
  }>(),
  {
    url: '',
    initial: '',
    user: undefined,
    socialEntity: undefined,
    isLoading: false,
    size: 7,
  }
);

// const defAvatar = `https://source.unsplash.com/n1B6ftPB5Eg`;

const imgSrc = computed(() => {
  if (props.user && props.user.avatarUrl) return props.user.avatarUrl;
  else if (props.socialEntity && props.socialEntity.avatarThumbUrl)
    return props.socialEntity.avatarThumbUrl;
  else if (
    props.socialEntity &&
    'socialAccounts' in props.socialEntity &&
    props.socialEntity.socialAccounts.length > 0
  ) {
    return (
      props.socialEntity.socialAccounts.find((sa) => sa.avatarUrl)?.avatarUrl ??
      ''
    );
  } else if (props.url) return props.url;
  else return '';
});

const initials = computed(() => {
  if (props.user) {
    return props.user.firstName
      ? `${props.user.firstName ? props.user.firstName[0] : '?'}${props.user.lastName && !props.user.lastName.startsWith('(') ? props.user.lastName[0] : ''}`.toUpperCase()
      : props.user.email?.slice(0, 2).toUpperCase();
  } else if (props.socialEntity)
    return props.socialEntity.fullName[0]?.toUpperCase();
  else if (props.initial) return props.initial;
  else return '';
});

const fullName = computed(() => {
  if (props.user) return useUserName(props.user);
  else if (props.socialEntity) return props.socialEntity.fullName;
  else return '';
});
</script>

<template>
  <USkeleton
    v-if="props.isLoading"
    :class="`h-${props.size} w-${props.size}`"
    :ui="{
      rounded: 'rounded-full',
      background: 'bg-hub-l-gray-400/[0.68] dark:bg-hub-d-gray-300',
    }"
  />

  <div
    v-else
    :class="`h-${props.size} w-${props.size} bg-ht-btn-gray overflow-hidden rounded-full border border-ht-border-3 justify-center items-center inline-flex text-sm font-medium`"
    :title="fullName"
  >
    <span v-if="imgSrc === ''">{{ initials }}</span>
    <img
      v-else
      class="w-full h-full object-cover object-center"
      :src="imageAssetSrc(imgSrc)"
      alt="avatar"
      referrerpolicy="no-referrer"
    />
  </div>
</template>
